import React from "react";
import TableMain from "../Common/table/TableMain";
import { useTranslation } from "react-i18next";
import { getLastPage } from "../../utils/getLastPage";
import TicketFilter from "./TicketFilter";

const TicketsTable = (props) => {
  const { t } = useTranslation();
  const headers = [
    t("slno"),
    t("ticket_id"),
    t("subject"),
    t("assignee"),
    t("status"),
    t("category"),
    t("priority"),
    t("created_on"),
    t("last_updated"),
    t("timeline"),
  ];
  // const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount);
  const lastPage = getLastPage(props.itemsPerPage, 1);

  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <TicketFilter />
        <div className="table-responsive min-hieght-table ticket_system">
          <TableMain
            headers={headers}
            data={props.data}
            startPage={1}
            currentPage={props.currentPage}
            totalPages={lastPage}
            type={props.type}
            itemsPerPage={props.itemsPerPage}
            setItemsPerPage={props.setItemsPerPage}
            setCurrentPage={props.setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketsTable;
