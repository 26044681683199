import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DOMPurify from "dompurify";

const MailListContainerContent = ({
  mailContent,
  mailData,
  setSingleMailId,
  setMailCheck,
  activeTab,
  setSelectedContacts,
  setShowMailPlaceholder,
  page,
  setPage,
}) => {
  const { t } = useTranslation();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [messageArray, setMessageArray] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const areAllChecked = () => {
    const selectedIds = Object.keys(selectedCheckboxes);
    return mailContent.length > 0 && selectedIds.length === mailContent.length;
  };

  const handleSingleMail = (id) => {
    if (activeTab !== "replicaInbox") {
      setSingleMailId(id);
      setMailCheck(true);
    } else {
      setSelectedContacts(id);
      setShowMailPlaceholder(false);
    }
  };

  const toggleAllCheckboxes = () => {
    const allChecked = areAllChecked();
    const updatedSelectedCheckboxes = {};
    mailContent.forEach((item) => {
      updatedSelectedCheckboxes[item.id] = !allChecked;
    });

    setSelectedCheckboxes(updatedSelectedCheckboxes);
    setSelectAllChecked(!allChecked);
  };

  const toggleCheckbox = (id) => {
    setSelectedCheckboxes((prevSelectedCheckboxes) => ({
      ...prevSelectedCheckboxes,
      [id]: !prevSelectedCheckboxes[id],
    }));
    setSelectAllChecked(areAllChecked());
  };

  const handleScroll = () => {
    const container = document.getElementById('mail-list-container');
    if (
      container.scrollTop + container.clientHeight === container.scrollHeight &&
      !isFetching
    ) {
      setIsFetching(true);
    }
  };
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFetching]);

  useEffect(() => {
    if (isFetching) {
  
      setTimeout(() => {
        // Update the state with the new data
        setIsFetching(false);

        // Implement logic to determine the next page
        // For example, you can calculate totalPages based on totalAmount and perPage
        const totalPages = Math.ceil(mailData?.totalCount / 10);

        if (page < totalPages) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 500); // Simulating a delay for API call, adjust as needed
    }
  }, [isFetching, mailData]);
  useEffect(() => {
    const messages = mailContent;
    const parsedMessages = messages?.map((message) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message.message, "text/html");
      const firstPTag = doc.querySelector("p");
      if (firstPTag) {
        return {
          id: message.id,
          subject: message.subject,
          createdAt: message.createdAt,
          message: firstPTag.innerHTML.trim(),
        };
      } else {
        return { id: message.id, message: "" }; // If there is no <p> tag, you may want to handle this case accordingly
      }
    });
    setMessageArray(parsedMessages);
  }, [mailContent]);

  return (
    <>
      <div className="border-bottom pb-4 mb-3 px-3 selectall_checkbox">
        {/* <label
          htmlFor="mailcheckbox"
          className="form-check-label"
          style={{ marginRight: "12px", transform: "scale(1.5)"}}
        >
          <input
            id="mailcheckbox"
            type="checkbox"
            className="form-check-input"
            checked={selectAllChecked}
            onChange={toggleAllCheckboxes}
          />
          <i className="input-helper"></i>
        </label> */}
        {t(activeTab)}
        {/* <svg className="more" viewBox="0 0 24 24" height="24px" width="24px">
          <path d="M14 6a2 2 0 11-4 0 2 2 0 014 0zM14 12a2 2 0 11-4 0 2 2 0 014 0zM14 18a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg> */}
      </div>

      <div className="mail-list-container-scrl">
        <>
          {mailContent?.length === 0 ? (
            <div className="no-data-mail-cnt">
              <img src="/images/no-mail-image1.jpg" alt="" />
            </div>
          ) : (
            <>
              {messageArray?.map((item, index) => (
                <div className="mail-list" key={index}>
                  {/* <div className="form-check">
                    <label
                      htmlFor={`checkbox-${index}`}
                      className="form-check-label"
                    >
                      <input
                        id={`checkbox-${index}`}
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedCheckboxes[item.id] || false}
                        onChange={() => toggleCheckbox(item.id)}
                      />
                      <i className="input-helper"></i>
                    </label>
                  </div> */}
                  <div
                    className="content"
                    onClick={() => handleSingleMail(item.id)}
                  >
                    <p className="sender-name">{item.subject}</p>
                    <p className="sender-name">
                      {" "}
                      {moment(item.createdAt).format(
                        "ddd, MMM D, YYYY [at] h:mm A"
                      )}
                    </p>
                    <p
                      className="message_text"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.message),
                      }}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      </div>
    </>
  );
};

export default MailListContainerContent;
