import React from "react";
import SubmitButton from "../Common/buttons/SubmitButton";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const UpgradeComponents = ({
    data,
    handleUpgradeClick,
    currency,
    conversionFactor,
}) => {
    const { t } = useTranslation();
    return (
        <div className="package_upgrade_cnt_sec">
            <div className="plan-options">
                {!data ? (
                    <>
                        <Skeleton
                            height={550}
                            highlightColor="#d9d1e1"
                            width={350}
                            count={1}
                        />
                        <Skeleton
                            height={550}
                            highlightColor="#d9d1e1"
                            width={350}
                            count={1}
                        />
                        <Skeleton
                            height={550}
                            highlightColor="#d9d1e1"
                            width={350}
                            count={1}
                        />
                    </>
                ) : (
                    data?.map((item, index) => (
                        <div key={index}>
                            {item?.upgradable === 0 && (
                                <div className="plan old_plan" key={index}>
                                    <div className="plan-header">
                                        <h5 className="plan-heading">
                                            {item?.pack?.name}
                                        </h5>
                                    </div>
                                    <div className="upgrade_desc">
                                        <p className="amnt_view_plan">
                                            <span className="plan-amount">
                                                {item?.board?.boardName}
                                            </span>
                                            <span className="pvValuewPlan">{`${t(
                                                "amount"
                                            )} : ${item?.amount}`}</span>
                                        </p>
                                    </div>

                                    <p className="plan_btn">
                                        <button className="plan-choose">
                                            {t("existing")}
                                        </button>
                                    </p>
                                </div>
                            )}
                            {item?.upgradable === 1 && (
                                <div className="plan current_plan" key={index}>
                                    <div className="plan-header">
                                        <h3 className="plan-heading">
                                            {item?.pack?.name}
                                        </h3>
                                    </div>
                                    <div className="upgrade_desc">
                                        <p className="amnt_view_plan">
                                            <span className="plan-amount">
                                                {item?.board?.boardName}
                                            </span>
                                            <span className="pvValuewPlan">{`${t(
                                                "amount"
                                            )} : ${item?.amount}`}</span>
                                        </p>
                                    </div>
                                    <p className="plan_btn">
                                        <button className="plan-choose">
                                            {t("current")}
                                        </button>
                                    </p>
                                </div>
                            )}
                            {item?.upgradable === 2 && (
                                <div className="plan" key={index}>
                                    <div className="plan-header">
                                        <h3 className="plan-heading">
                                            {item?.pack?.name}
                                        </h3>
                                    </div>
                                    <div className="upgrade_desc">
                                        <p className="amnt_view_plan">
                                            <span className="plan-amount">
                                                {item?.board?.boardName}
                                            </span>
                                            <span className="pvValuewPlan">{`${t(
                                                "amount"
                                            )} : ${item?.amount}`}</span>
                                        </p>
                                    </div>
                                    <p className="plan_btn">
                                        <SubmitButton
                                            className="plan-choose"
                                            text="upgrade"
                                            isSubmitting={!item?.upgradable}
                                            click={() =>
                                                handleUpgradeClick(item)
                                            }
                                        />
                                    </p>
                                </div>
                            )}
                            {item?.upgradable === 3 && (
                                <div className="plan old_plan" key={index}>
                                    <div className="plan-header">
                                        <h5 className="plan-heading">
                                            {item?.pack?.name}
                                        </h5>
                                    </div>
                                    <div className="upgrade_desc">
                                        <p className="amnt_view_plan">
                                            <span className="plan-amount">
                                                {item?.board?.boardName}
                                            </span>
                                            <span className="pvValuewPlan">{`${t(
                                                "amount"
                                            )} : ${item?.amount}`}</span>
                                        </p>
                                    </div>
                                    <p className="plan_btn">
                                        <button className="plan-choose">
                                            <i className="fa-solid fa-lock"></i>{" "}
                                            {t("locked")}
                                        </button>
                                    </p>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default UpgradeComponents;
