import React from 'react';
import { useTranslation } from 'react-i18next';

function TicketFilter() {
  const {t} = useTranslation()
  
  return (
    <div className="filter_Section">
      <div className="row align-items-center">
        <div className="col-md-2">
          <div className="right_search_div">
            <label htmlFor="ticketId">{t('ticket_id')}</label>
            <input type="text" className="form-control" id="ticketId" placeholder="Ticket Id" />
          </div>
        </div>
        <div className="col-md-2">
          <div className="right_search_div">
            <label htmlFor="category">{t('category')}</label>
            <select name="category" className="form-control" id="category">
              <option value="All">All</option>
              {/* Add more options here */}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="right_search_div">
            <label htmlFor="priority">{t('priority')}</label>
            <select name="priority" className="form-control" id="priority">
              <option value="All">All</option>
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="right_search_div">
            <label htmlFor="status">{t('status')}</label>
            <select name="status" className="form-control" id="status">
              <option value="All">All</option>
            </select>
          </div>
        </div>
        <div className="col-md-2 mt-4">
          <div className="right_search_div d-flex gap-1">
            <button className="btn btn-primary">{t('search')}</button>
            <button className="btn btn-secondary">{t('reset')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketFilter;
