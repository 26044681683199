import React from "react";
import { useTranslation } from "react-i18next";
import UpgradeComponents from "../../components/upgradeBoard/UpgradeComponents";
import { ApiHook } from "../../hooks/apiHook";
import UpgradePaymentModal from "../../components/upgradeBoard/UpgradePaymentModal";
import { useState } from "react";
import { useSelector } from "react-redux";

const UpgradeBoard = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [selectedUpgradePlan, setSelectedUpgradePlan] = useState();
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const handleUpgradeClick = (plan) => {
        setShow(true);
        setSelectedUpgradePlan(plan);
    };
    const productsList = ApiHook.CallGetUpgradeBoards();
    const currentBoard = productsList?.find((item) => item.upgradable === 1);

    return (
        <>
            <div className="page_head_top">{t("upgrade")}</div>
            <UpgradeComponents
                data={productsList}
                handleUpgradeClick={handleUpgradeClick}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
            />
            <UpgradePaymentModal
                show={show}
                setShow={setShow}
                currentProduct={currentBoard}
                data={selectedUpgradePlan}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
            />
        </>
    );
};

export default UpgradeBoard;
