import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SubmitButton from "../Common/buttons/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import { setGenologyBoardId } from "../../store/reducers/treeReducer";

const TreeViewNavbar = ({ menu, searchUsername, setSearchUsername }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const pathname = location.pathname;
    const [username, setUsername] = useState("");
    const [selectedMenu, setSelectedMenu] = useState(false);
    const dispatch = useDispatch();
    const boardId = useSelector((state) => state.tree?.genologyBoardId);

    if (location.pathname === "/sponsor-tree") {
        ApiHook.CallSponsorTreeList("", "", searchUsername);
    } else if (location.pathname === `/genealogy-tree`) {
        ApiHook.CallGenealogyTreeList("", "", searchUsername, boardId);
    } else {
        //  Do nothing
    }
    const trimmedPathname = pathname.replace(/^\//, "");
    const plan = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus?.mlm_plan
    );

    const boardIdList = useSelector(
        (state) => state.tree?.genealogyList?.boardIdList
    );

    const setBoardIdInRedux = (boardId) => {
        dispatch(setGenologyBoardId(boardId));
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setUsername(value);
    };
    const handleSearch = () => {
        setSearchUsername(username);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            // If Enter key is pressed, trigger the search
            handleSearch();
        }
    };
    const handleSideMenuToggle = () => {
        setSelectedMenu(!selectedMenu);
    };

    return (
        <>
            <div className="page_head_top">
                {t(menu)}
                <div
                    className="right_btn_mob_toggle"
                    onClick={handleSideMenuToggle}
                >
                    <i className="fa fa-bars"></i>
                </div>
            </div>
            <div
                className={`tree_view_top_filter_bar mt-2 hide_show_mobile ${
                    selectedMenu ? "show_mn" : ""
                }`}
            >
                <div className="row justify-content-between">
                    <div
                        className={`col-md-8 hide_show_mobile ${
                            selectedMenu ? "show_mn" : ""
                        }`}
                        style={{ display: "flex" }}
                    >
                        {/* <NavLink
                            to="/genealogy-tree"
                            className={({ isActive }) =>
                                isActive
                                    ? "btn_ewallt_page active"
                                    : "btn_ewallt_page"
                            }
                        >
                            {t("genealogyTree")}
                        </NavLink> */}
                        <div className="genology_dropdown_container">
                            <div>
                                {/* <button
                                    className="btn_ewallt_page"
                                    id="genology_hover_btn"
                                >
                                    {t("genealogyTree")}
                                </button> */}
                                <NavLink
                                    to={"/genealogy-tree"}
                                    className={({ isActive }) =>
                                        isActive
                                            ? "btn_ewallt_page active"
                                            : "btn_ewallt_page"
                                    }
                                    id="genology_hover_btn"
                                    style={{
                                        marginRight: "0px",
                                        borderTopRightRadius: "0px",
                                        borderBottomRightRadius: "0px",
                                    }}
                                >
                                    {t("genealogyTree")}
                                </NavLink>
                                <NavLink
                                    to={"/genealogy-tree"}
                                    className={({ isActive }) =>
                                        isActive
                                            ? "btn_ewallt_page active"
                                            : "btn_ewallt_page"
                                    }
                                    style={{
                                        borderTopLeftRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                    }}
                                >
                                    <i class="fad fa-chevron-down"></i>
                                </NavLink>{" "}
                            </div>

                            <div
                                className="genology_dropdown_content"
                                id="genology_dropdown_content"
                            >
                                {boardIdList &&
                                    Object.keys(boardIdList).map((key) => (
                                        <NavLink
                                            key={key}
                                            to={`/genealogy-tree`}
                                            className={() =>
                                                key === boardId
                                                    ? "genology_button_page active"
                                                    : "genology_button_page"
                                            }
                                            onClick={() =>
                                                setBoardIdInRedux(key)
                                            }
                                            style={{ boxShadow: "unset" }}
                                        >
                                            {boardIdList[key]}
                                        </NavLink>
                                    ))}
                            </div>
                        </div>

                        {plan !== "Unilevel" && (
                            <NavLink
                                to="/sponsor-tree"
                                className={({ isActive }) =>
                                    isActive
                                        ? "btn_ewallt_page active"
                                        : "btn_ewallt_page"
                                }
                            >
                                {t("sponsorTree")}
                            </NavLink>
                        )}
                        <NavLink
                            to={"/tree-view"}
                            className={({ isActive }) =>
                                isActive
                                    ? "btn_ewallt_page active"
                                    : "btn_ewallt_page"
                            }
                        >
                            {t("treeView")}
                        </NavLink>
                        <NavLink
                            to={"/downline-members"}
                            className={({ isActive }) =>
                                isActive
                                    ? "btn_ewallt_page active"
                                    : "btn_ewallt_page"
                            }
                        >
                            {t("downlineMembers")}
                        </NavLink>
                        {plan !== "Unilevel" && (
                            <NavLink
                                to={"/referral-members"}
                                className={({ isActive }) =>
                                    isActive
                                        ? "btn_ewallt_page active"
                                        : "btn_ewallt_page"
                                }
                            >
                                {t("referralMembers")}
                            </NavLink>
                        )}
                    </div>
                    {(trimmedPathname === "genealogy-tree" ||
                        trimmedPathname === "sponsor-tree") && (
                        <div className="col-md-4 mob_filter_right">
                            <div className="right_search_div d-flex gap-1">
                                <Form.Group>
                                    <Form.Control
                                        id="Search"
                                        type="text"
                                        placeholder="Search"
                                        onChange={(e) => handleChange(e)}
                                        onKeyPress={(e) => handleKeyPress(e)}
                                        value={username}
                                        required
                                    />
                                </Form.Group>
                                <SubmitButton
                                    className="btn btn-primary"
                                    text="Search"
                                    click={handleSearch}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TreeViewNavbar;
