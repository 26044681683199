import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TicketDetails = () => {
  const { t } = useTranslation();
  const messageFeeds = [
    {
      id: 1,
      content:
        "Quisque consequat arcu eget odio cursus, ut tempor arcu vestibulum. Etiam ex arcu, porta a urna non, lacinia pellentesque orci. Proin semper sagittis erat, eget condimentum sapien viverra et. Mauris volutpat magna nibh, et condimentum est rutrum a. Nunc sed turpis mi. In eu massa a sem pulvinar lobortis.",
      date: "20/02/2015 at 09:00",
      isLeft: true,
    },
    {
      id: 2,
      content:
        "Mauris volutpat magna nibh, et condimentum est rutrum a. Nunc sed turpis mi. In eu massa a sem pulvinar lobortis.",
      date: "20/02/2015 at 09:30",
      isLeft: false,
    },
    {
      id: 3,
      content:
        "Quisque consequat arcu eget odio cursus, ut tempor arcu vestibulum. Etiam ex arcu, porta a urna non, lacinia pellentesque orci. Proin semper sagittis erat, eget condimentum sapien viverra et. Mauris volutpat magna nibh, et condimentum est rutrum a. Nunc sed turpis mi. In eu massa a sem pulvinar lobortis.",
      date: "20/02/2015 at 09:00",
      isLeft: true,
    },
    {
      id: 4,
      content:
        "Mauris volutpat magna nibh, et condimentum est rutrum a. Nunc sed turpis mi. In eu massa a sem pulvinar lobortis.",
      date: "20/02/2015 at 09:30",
      isLeft: false,
    },
  ];

  const ticketDetails = [
    { label: "ticket_id", value: "#15487854" },
    { label: "ticket_status", value: "New" },
    { label: "created_on", value: "2023-10-20" },
    { label: "last_updated", value: "2023-10-20" },
    { label: "category", value: "Payout" },
    { label: "priority", value: "High" },
  ];

  return (
    <div>
      <div className="page_head_top">{t("ticket_details")}</div>
      <div className="support_cnt_box">
        <div className="row">
          <div className="col-md-6">
          <h4>
            <Link to={"/support-center"} className="back_btn">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>
          </h4>
            <div className="support_chat_left_box">
              <div className="bootstrap snippets bootdey">
                <div className="tile tile-alt" id="messages-main">
                  <div className="ms-body">
                    <div className="action-header clearfix">
                      <div className="visible-xs" id="ms-menu-trigger">
                        <i className="fa fa-bars"></i>
                      </div>

                      <div className="pull-left avatar_top">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar2.png"
                          alt=""
                          className="img-avatar m-r-10"
                        />
                        <div className="lv-avatar pull-left"></div>
                        <span>David Parbell</span>
                      </div>
                    </div>
                    <div className="message_body_area">
                      {messageFeeds.map((feed) => (
                        <div
                          key={feed.id}
                          className={`message-feed ${
                            feed.isLeft ? "media" : "right"
                          }`}
                        >
                          <div className="pull-left">
                            <img
                              src="https://bootdey.com/img/Content/avatar/avatar1.png"
                              alt=""
                              className="img-avatar"
                            />
                          </div>
                          <div className="media-body">
                            <div className="mf-content">{feed.content}</div>
                            <small className="mf-date">
                              <i className="fa fa-clock-o"></i> {feed.date}
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="msb-reply">
                      <textarea placeholder="What's on your mind..."></textarea>
                      <button>
                        <i className="fa-regular fa-paper-plane"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <table className="table border">
              <tbody>
                {ticketDetails.map((detail, index) => (
                  <tr key={index}>
                    <th>{t(detail.label)}</th>
                    <td>:</td>
                    <td>{detail.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
