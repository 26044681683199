import React from "react";
import TicketHeader from "./TicketHeader";
import TicketForm from "./TicketForm";

const CreateTicket = () => {
  return (
    <>
      <TicketHeader />
      <TicketForm />
    </>
  );
};

export default CreateTicket
