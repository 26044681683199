import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const TicketForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="page_head_top">{t("create_ticket")}</div>
      <div className="support_cnt_box">
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="create_ticket_row">
                <label htmlFor="ticketId">
                  {t("ticket_id")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ticketId"
                  {...register("ticketId", {
                    required: t("this_field_is_required"),
                  })}
                />
                {errors.ticketId && (
                  <span role="alert" className="error-message-validator">
                    {errors.ticketId.message}
                  </span>
                )}
              </div>
              <div className="create_ticket_row">
                <label htmlFor="priority">
                  {t("priority")} <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="priority"
                  {...register("priority", {
                    required: t("this_field_is_required"),
                  })}
                >
                  <option value="">{t("select")}</option>
                  {/* Add options for priorities */}
                </select>
                {errors.priority && (
                  <span role="alert" className="error-message-validator">
                    {errors.priority.message}
                  </span>
                )}
              </div>
              <div className="create_ticket_row">
                <label htmlFor="category">
                  {t("category")} <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="category"
                  {...register("category", {
                    required: t("this_field_is_required"),
                  })}
                >
                  <option value="">{t("select")}</option>
                  {/* Add options for categories */}
                </select>
                {errors.category && (
                  <span role="alert" className="error-message-validator">
                    {errors.category.message}
                  </span>
                )}
              </div>
              <div className="create_ticket_row">
                <label htmlFor="message">
                  {t("message_to_admin")} <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  {...register("message", {
                    required: t("this_field_is_required"),
                  })}
                  cols="30"
                  rows="5"
                ></textarea>
                {errors.message && (
                  <span role="alert" className="error-message-validator">
                    {errors.message.message}
                  </span>
                )}
              </div>
              <div className="create_ticket_row">
                <label htmlFor="attachment">{t("attachment")}</label>
                <input
                  type="file"
                  className="form-control"
                  id="attachment"
                  {...register("attachment")}
                />
              </div>
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary float-end">
                  {t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketForm;
