import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TicketTimeline = () => {
  const { t } = useTranslation();
  const timelineItems = [
    { date: "20-Oct-2023", description: "Ticket Created" },
    { date: "21-Oct-2023", description: "Issue Investigated" },
    { date: "22-Oct-2023", description: "Solution Implemented" },
    { date: "20-Oct-2023", description: "Ticket Created" },
    { date: "21-Oct-2023", description: "Issue Investigated" },
    { date: "22-Oct-2023", description: "Solution Implemented" },
  ];

  useEffect(() => {
    const items = document.querySelectorAll(".timeline li");

    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function callbackFunc() {
      for (let i = 0; i < items.length; i++) {
        if (isElementInViewport(items[i])) {
          items[i].classList.add("in-view");
        }
      }
    }
    window.addEventListener("transitionstart", callbackFunc);
    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);

    return () => {
      window.addEventListener("transitionstart", callbackFunc);
      window.removeEventListener("load", callbackFunc);
      window.removeEventListener("resize", callbackFunc);
      window.removeEventListener("scroll", callbackFunc);
    };
  }, []);

  return (
    <>
      <div className="page_head_top">{t("timeline")}</div>
      <div className="support_cnt_box">
        <section className="timeline">
          <h4>
            <Link to={"/support-center"} className="back_btn">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>{" "}
            Ticket : #55487454asd4as5d
          </h4>
          <ul>
            {timelineItems.map((item, index) => (
              <li key={index}>
                <div>
                  <time>{item.date}</time>
                  <h5>{item.description}</h5>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium At vero eos et accusamus et iusto
                    odio dignissimos ducimus qui blanditiis praesentium
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </>
  );
};

export default TicketTimeline;
