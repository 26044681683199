import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TicketHeader from "../../components/Support/TicketHeader";
import TicketsTable from "../../components/Support/TicketsTable";

const MainSupport = () => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const ticketData = [
    {
      id: 1,
      ticketId: "#154876",
      subject: "Issue with Payment Gateway",
      assignee: "John Doe",
      status: "Open",
      category: "Payment",
      priority: "High",
      createdOn: "2023-10-12T15:10:07Z",
      lastUpdated: "2023-10-12T15:10:07Z",
      timeline: 1,
    },
    {
      id: 2,
      ticketId: "#154877",
      subject: "Login Problem",
      assignee: "Jane Smith",
      status: "In Progress",
      category: "Account",
      priority: "Medium",
      createdOn: "2023-10-13T09:20:15Z",
      lastUpdated: "2023-10-14T11:25:32Z",
      timeline: 1,
    },
    {
      id: 3,
      ticketId: "#154878",
      subject: "Bug in Dashboard",
      assignee: "Alex Johnson",
      status: "Resolved",
      category: "Dashboard",
      priority: "Low",
      createdOn: "2023-10-15T11:45:22Z",
      lastUpdated: "2023-10-16T10:30:18Z",
      timeline: 1,
    },
  ];

  return (
    <>
      <TicketHeader />
      <div className="page_head_top">{t("support")}</div>
      <TicketsTable
        data={ticketData}
        type={'ticket'}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
      />
    </>
  );
};

export default MainSupport;
