import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import GenealogyTreeContent from "../../components/Tree/genealogy-tree/GenealogyTreeContent";

const GenealogyTree = () => {
    const [selectedUserId, setSelectedUserId] = useState("");
    const [searchUsername, setSearchUsername] = useState("");
    const [doubleUser, setDoubleUser] = useState("");
    // const [boardId, setBoardId] = useState("1");
    return (
        <>
            <TreeViewNavbar
                menu={"genealogyTree"}
                searchUsername={searchUsername}
                setSearchUsername={setSearchUsername}
                // boardId={boardId}
                // setBoardId={setBoardId}
            />
            <GenealogyTreeContent
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                doubleUser={doubleUser}
                setDoubleUser={setDoubleUser}
                searchUsername={searchUsername}
                setSearchUsername={setSearchUsername}
                // boardId={boardId}
            />
        </>
    );
};

export default GenealogyTree;
