import React, { useEffect, useState } from "react";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import TeamMembers from "../../components/Dashboard/TeamMembers";
import TeamMembersEarningSection from "../../components/Dashboard/TeamPerformance";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RankComponent from "../../components/Dashboard/RankComponent";
import { Modal, Button } from "react-bootstrap";

const Dashboard = () => {
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem("user"));
    const joiningChartData = useSelector(
        (state) => state.dashboard?.dashboardOne
    );
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );

    // --------------------------------------------- API -------------------------------------------------
    const dashboard = ApiHook.CallDashboardTiles();
    const dashboardDetails = ApiHook.CallDashboardDetails();

    const [userModel, setUserModel] = useState(false);
    const [showModel, setShowModel] = useState(false);
    useEffect(() => {
        if (
            dashboardDetails?.data?.currentBoard?.popUpBoard == 1 &&
            !userModel
        ) {
            setShowModel(true);
        }
    }, [dashboardDetails]);

    const updatePopupBoard = ApiHook.CallUpdateDashboardPopup(
        userModel,
        setShowModel
    );

    if (dashboard.isFetching) {
        // return null;
    }

    const closeModal = () => {
        setUserModel(true);
        setShowModel(false);
    };
    return (
        <>
            <div className="page_head_top">{t("dashboard")}</div>
            <div className="center_content_head">
                <span>
                    {t("welcome")} {user?.fullName}{" "}
                </span>
            </div>
            <QuickBalance
                tiles={dashboard?.data}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
            />
            <div className="joining_Teammbr_section">
                <div className="row">
                    <JoiningChart charts={joiningChartData} />
                    <TeamMembers members={dashboardDetails?.data?.newMembers} />
                </div>
            </div>
            <div className="team_members_earning_section">
                <div className="row">
                    <TeamMembersEarningSection
                        topEarners={dashboardDetails?.data?.topEarners}
                        currency={userSelectedCurrency}
                        conversionFactor={conversionFactor}
                    />
                    {!!moduleStatus?.rank_status && (
                        <RankComponent
                            ranks={dashboardDetails?.data?.ranks}
                            currentRank={dashboardDetails?.data?.currentRank}
                        />
                    )}
                    <EarningsExpenses
                        earnings={dashboardDetails?.data?.earnings}
                        currency={userSelectedCurrency}
                        conversionFactor={conversionFactor}
                    />
                </div>
            </div>
            {/* <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    top: "0px",
                    left: "0px",
                    minWidth: "30%",
                    minHeight: "20%",
                    backgroundColor: "#fff",
                    zIndex: 10000000,
                }}
            >
                <span>board upgrade popup</span>
            </div> */}
            <div>
                <Modal show={showModel} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eligible For Next Board</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Congratulations!! Your fourth level has achieved one
                            person. You are now eligible to upgrade to next
                            board by clicking the 'Upgrade Board' button
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                        </Button>
                        {/* Additional buttons or actions can be placed here */}
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default Dashboard;
