import React, { useState } from "react";
import { useParams } from "react-router";
import { ApiHook } from "../../hooks/apiHook";
import { Form } from "react-bootstrap";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";

const LeadsForm = () => {
  const params = useParams();
  const hash = params?.hash;
  const username = params?.username;
  const companyDetails = ApiHook.CallGetCompanyDetails();
  const AddLcpLeadMutation = ApiHook.CallAddLcpLead();


  const [lcpFormData, setLcpFormData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    skypeId: "",
    mobileNo: "",
    countryId: "",
    description: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    firstName: null,
    lastName: null,
    emailId: null,
    skypeId: null,
    mobileNo: null,
    countryId: null,
    description: null,
  });
  

  const handleChange = (e) => {
    const { id, value } = e.target;
    setLcpFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrorMessage((prevData) => ({
      ...prevData,
      [id]: null,
    }));
    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [id]: "*Required",
      }));
    }
    if (id === "emailId") {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(value)) {
        setErrorMessage((prevData) => ({
          ...prevData,
          emailId: "Invalid email format",
        }));
      }
    } else if (id === "mobileNo") {
      if (value.length < 5) {
        setErrorMessage((prevData) => ({
          ...prevData,
          mobileNo: "Minimum length is 5 digits",
        }));
      }
    }
  };

  const isFormValid = () => {
    const phoneNumber = lcpFormData?.mobileNo || "";
    const isValidPhoneNumber = /^\d{5,}$/.test(phoneNumber);
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return (
      lcpFormData?.firstName.trim() !== "" &&
      emailRegex.test(lcpFormData?.emailId.trim()) &&
      lcpFormData?.countryId.trim() !== "" &&
      isValidPhoneNumber
    );
  };

  const resetForm = () => {
    setLcpFormData({
      firstName: "",
      lastName: "",
      emailId: "",
      skypeId: "",
      mobileNo: "",
      countryId: "",
      description: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submitData = {
      ...lcpFormData,
      hash,
      username,
    };
    AddLcpLeadMutation.mutateAsync(submitData, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(res.data);
          resetForm();
        }
      },
    });
  };

  return (
    <section className="lcpBgSection">
      <div className="container centerDiv">
        <div className="box_view_section">
          <div className="box_view_lcp_left_sec">
            <div className="loginLogo">
              <img src={companyDetails?.companyProfile?.logo} alt="" />
            </div>
            <div className="lead_page_img">
              <img src="/images/lead_distribution.svg" alt="" />
            </div>
            <p className="lcpLeftTxt">
              <strong>“Connecting Dreams, Growing Together”</strong>
              <span>
                Take the first step towards MLM success by filling out this
                form!
              </span>
            </p>
          </div>
          <div className="box_view_lcp">
            <div className="loginLogo_right">
              <img src={companyDetails?.companyProfile?.logo} alt="" />
            </div>
            <h4>Fill out the form below</h4>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label>
                  First Name <span>*</span>
                </Form.Label>
                <Form.Control
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  value={lcpFormData.firstName}
                  onChange={(e) => handleChange(e)}
                  required
                  isInvalid={errorMessage?.firstName !== null}
                />
                <Form.Control.Feedback type="invalid">
                  {errorMessage.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={lcpFormData.lastName}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  Email Address<span>*</span>
                </Form.Label>
                <Form.Control
                  id="emailId"
                  type="text"
                  placeholder="Email"
                  value={lcpFormData.emailId}
                  onChange={(e) => handleChange(e)}
                  required
                  isInvalid={errorMessage.emailId !== null}
                />
                <Form.Control.Feedback type="invalid">
                  {errorMessage.emailId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  Skype ID
                </Form.Label>
                <Form.Control
                  id="skypeId"
                  type="text"
                  placeholder="Skype ID"
                  value={lcpFormData.skypeId}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  Phone/Cell Number<span>*</span>
                </Form.Label>
                <Form.Control
                  id="mobileNo"
                  type="number"
                  placeholder="Mobile Number"
                  value={lcpFormData.mobileNo}
                  onChange={(e) => handleChange(e)}
                  required
                  isInvalid={errorMessage?.mobileNo !== null}
                />
                <Form.Control.Feedback type="invalid">
                  {errorMessage.mobileNo}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Country<span>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  id="countryId"
                  onChange={(e) => handleChange(e)}
                  value={lcpFormData.countryId}
                >
                  <option value="default" defaultValue selected disabled>
                    Select a country
                  </option>
                  {companyDetails?.countries?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
                {errorMessage?.countryId && (
                  <div style={{ color: "red" }}>{errorMessage?.countryId}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  id="description"
                  type="text"
                  placeholder="Description"
                  value={lcpFormData.description}
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <SubmitButton
                isSubmitting={!isFormValid()}
                click={handleSubmit}
                text="Submit"
                className={"btn btn-primary submit_btn"}
              />
            </Form>
          </div>
        </div>
        <p className="lcp_copyright">Copyright © 2023 Infinte MLM Software.</p>
      </div>
    </section>
  );
};

export default LeadsForm;
